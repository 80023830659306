import React from 'react';

import AdvancedMapMarker from './AdvancedMapMarker';

const operations = {
	order: {
		background_color: '#F7F3D2',
		icon_name: 'ri-shopping-cart-2-fill',
		icon_size: 15,
		icon_color: '#EAAA08',
		border_color: '#EAAA08',
	},

	payment_collection: {
		background_color: '#E6F4D7',
		icon_name: 'ri-hand-coin-fill',
		icon_size: 15,
		icon_color: '#669F2A',
		border_color: '#669F2A',
	},

	new_client: {
		background_color: '#ECE9FE',
		icon_name: 'ri-user-add-fill',
		icon_size: 15,
		icon_color: '#7A5AF8',
		border_color: '#7A5AF8',
	},
};

const OperationMapMarker = ({ mapRef, operation, ...props }) => {
	const { background_color, border_color, icon_name, icon_size, icon_color } = operations[operation.type] || {};

	return (
		<AdvancedMapMarker
			mapRef={mapRef}
			id={operation.id}
			position={{ lat: operation.coordinates.latitude, lng: operation.coordinates.longitude }}
			infoWindowContent={operation.data.info_window_content}
			backgroundColor={background_color}
			borderColor={border_color}
			iconName={icon_name}
			iconSize={icon_size}
			iconColor={icon_color}
			{...props}
		/>
	);
};

export default OperationMapMarker;
