/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import ProfileDropdown from '../components/common/ProfileDropdown';
import LightDark from '../components/common/LightDark';

import { changeSidebarVisibility } from '../slices/thunks';
import { api } from '../config';

const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {
	const dispatch = useDispatch();

	const selectDashboardData = createSelector(
		state => state.Layout.sidebarVisibilitytype,
		sidebarVisibilitytype => sidebarVisibilitytype
	);

	// Inside your component
	const sidebarVisibilitytype = useSelector(selectDashboardData);

	const toggleMenuBtn = () => {
		const windowSize = document.documentElement.clientWidth;
		dispatch(changeSidebarVisibility('show'));

		if (windowSize > 767) document.querySelector('.hamburger-icon').classList.toggle('open');

		// For collapse horizontal menu
		if (document.documentElement.getAttribute('data-layout') === 'horizontal') {
			document.body.classList.contains('menu')
				? document.body.classList.remove('menu')
				: document.body.classList.add('menu');
		}

		// For collapse vertical and semibox menu
		if (
			sidebarVisibilitytype === 'show' &&
			(document.documentElement.getAttribute('data-layout') === 'vertical' ||
				document.documentElement.getAttribute('data-layout') === 'semibox')
		) {
			if (windowSize < 1025 && windowSize > 767) {
				document.body.classList.remove('vertical-sidebar-enable');
				document.documentElement.getAttribute('data-sidebar-size') === 'sm'
					? document.documentElement.setAttribute('data-sidebar-size', '')
					: document.documentElement.setAttribute('data-sidebar-size', 'sm');
			} else if (windowSize > 1025) {
				document.body.classList.remove('vertical-sidebar-enable');
				document.documentElement.getAttribute('data-sidebar-size') === 'lg'
					? document.documentElement.setAttribute('data-sidebar-size', 'sm')
					: document.documentElement.setAttribute('data-sidebar-size', 'lg');
			} else if (windowSize <= 767) {
				document.body.classList.add('vertical-sidebar-enable');
				document.documentElement.setAttribute('data-sidebar-size', 'lg');
			}
		}

		// Two column menu
		if (document.documentElement.getAttribute('data-layout') === 'twocolumn') {
			document.body.classList.contains('twocolumn-panel')
				? document.body.classList.remove('twocolumn-panel')
				: document.body.classList.add('twocolumn-panel');
		}
	};

	// * Company logo
	const [companyName, setCompanyName] = useState(null);
	const [companyLogo, setCompanyLogo] = useState(null);
	const [userRoleName, setUserRoleName] = useState(null);

	const selectUser = createSelector(
		state => state.Login,
		user => user.user
	);

	const user = useSelector(selectUser);

	useEffect(() => {
		if (localStorage.getItem('authUser')) {
			const { user: user_data, company } = JSON.parse(localStorage.getItem('authUser'));

			setCompanyName(company?.name);
			setCompanyLogo(company?.logo_file);

			setUserRoleName(user_data?.role_name);
		}
	}, [user]);

	return (
		<React.Fragment>
			<header id="page-topbar" className={headerClass}>
				<div className="layout-width">
					<div className="navbar-header">
						<div className="d-flex ms-1 ps-2">
							<div className="horizontal-logo ps-0">
								<div className="d-flex h-100 align-items-center">
									<Link to="/">
										{companyLogo ? (
											<img
												src={`${api.API_URL}/files/images/${companyLogo}`}
												alt="company-logo"
												style={{ height: 48, maxHeight: 48 }}
											/>
										) : (
											<h4 className="mb-0 text-uppercase fs-18">
												{userRoleName === 'admin' ? 'Admin' : companyName || ''}
											</h4>
										)}
									</Link>
								</div>
							</div>

							{userRoleName !== 'company_client' ? (
								<button
									onClick={toggleMenuBtn}
									type="button"
									className="btn btn-sm ms-3 px-0 fs-16 header-item vertical-menu-btn topnav-hamburger"
									id="topnav-hamburger-icon">
									<span className="hamburger-icon">
										<span></span>
										<span></span>
										<span></span>
									</span>
								</button>
							) : null}
						</div>

						<div className="d-flex align-items-center">
							{/* LanguageDropdown */}
							{/* <LanguageDropdown /> */}

							{/* FullScreenDropdown */}
							{/* <FullScreenDropdown /> */}

							{/* Dark/Light Mode set */}
							<LightDark layoutMode={layoutModeType} onChangeLayoutMode={onChangeLayoutMode} />

							{/* ProfileDropdown */}
							<ProfileDropdown />
						</div>
					</div>
				</div>
			</header>
		</React.Fragment>
	);
};

export default Header;
