import React, { useRef, useEffect } from 'react';

const AdvancedMapMarker = ({
	mapRef,
	id,
	position,
	infoWindowContent,
	focusedElement,
	setFocusedElement,
	setHoveredElement,
	backgroundColor = 'white',
	borderColor = '#054AB4',
	iconName = '#054AB4',
	iconSize = 18,
	iconColor = 'white',
}) => {
	const markerRef = useRef();

	useEffect(() => {
		const icon = document.createElement('div');

		icon.innerHTML = `<i class="${iconName}" style="font-size: ${iconSize}px;"></i>`;

		const pin_icon = new window.google.maps.marker.PinElement({
			glyph: icon,
			scale: 1.15,
			glyphColor: iconColor,
			background: backgroundColor,
			borderColor,
		});

		const advanced_marker = new window.google.maps.marker.AdvancedMarkerElement({
			content: pin_icon.element,
			gmpClickable: true,
		});

		// On click
		advanced_marker.addListener('click', () => {
			setFocusedElement({ type: 'marker', id });
		});

		// On mouse over
		advanced_marker.content.addEventListener('mouseenter', () => {
			setHoveredElement({ type: 'marker', id });
		});

		// On mouse out
		advanced_marker.content.addEventListener('mouseleave', () => {
			setHoveredElement({ type: null, id: null });
		});

		markerRef.current = advanced_marker;

		return () => {
			advanced_marker.map = null;
		};
	}, []);

	useEffect(() => {
		if (!markerRef?.current) {
			return;
		}

		if (!mapRef?.current) {
			return;
		}

		if (!position?.lat || !position?.lng) {
			return;
		}

		markerRef.current.map = mapRef.current.map;
		markerRef.current.position = position;
	}, [mapRef, position]);

	useEffect(() => {
		if (focusedElement?.id === id) {
			openInfoWindow();
		}
	}, [focusedElement]);

	const openInfoWindow = () => {
		if (!infoWindowContent) {
			return;
		}

		mapRef.current.infoWindow.close();
		mapRef.current.infoWindow.setHeaderDisabled(true);
		mapRef.current.infoWindow.setContent(infoWindowContent);
		mapRef.current.infoWindow.setOptions({ minWidth: 240, maxWidth: 350 });
		mapRef.current.infoWindow.open(markerRef.current.map, markerRef.current);

		// Center map on maker
		mapRef.current.map.panTo(position);
	};

	return <></>;
};

export default AdvancedMapMarker;
