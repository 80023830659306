import React from 'react';
import { Col, Row } from 'reactstrap';

import classnames from 'classnames';

import { formatHour } from '../../helpers/dates_helper';

const StartEventItem = ({ event, focusedElement, setFocusedElement, hoveredElement, setHoveredElement }) => {
	const { id } = event;

	const start_at = formatHour(event?.recorded_at);

	const onClick = () => {
		setFocusedElement({ type: 'marker', id });
	};

	const onMouseEnter = () => {
		setHoveredElement({ type: 'marker', id });
	};

	const onMouseLeave = () => {
		setHoveredElement({ type: null, id: null });
	};

	return (
		<Row
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			className={classnames(
				'd-flex align-items-center gap-2 mx-2 px-2 rounded cursor-pointer map-start-event-item',
				{ hovered: hoveredElement?.type === 'marker' && hoveredElement?.id === id },
				{ selected: focusedElement?.type === 'marker' && focusedElement?.id === id }
			)}>
			<Col className="col-auto mx-0 px-0" style={{ paddingTop: 7, paddingBottom: 7 }}>
				<div
					className="position-absolute"
					style={{
						bottom: 0,
						height: 7,
						width: 8,
						padding: 0,
						marginLeft: 11,
						backgroundColor: '#299CDB',
					}}
				/>

				<div
					className="bg-success-subtle shadow"
					style={{
						display: 'inline-flex',
						height: 30,
						minWidth: 30,
						borderRadius: 15,
					}}>
					<div className="d-flex h-100 w-100 justify-content-center align-items-center px-0 mx-0">
						<i className={'ri-flag-fill text-success'} style={{ fontSize: 15 }}></i>
					</div>
				</div>
			</Col>

			<Col className="mx-0 px-0">
				<h5 className="fs-14 mb-0 mx-0 px-0">Inicio</h5>
			</Col>

			<Col className="col-auto mx-0 px-0">
				<p className="mb-0 fs-12">{start_at} hs</p>
			</Col>
		</Row>
	);
};

export default StartEventItem;
