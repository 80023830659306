import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Row, Button, Form, FormFeedback, Spinner } from 'reactstrap';

import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ToastContainer } from 'react-toastify';

import { resetState } from '../../actions/resetState';
import { loginUser } from '../../slices/thunks';
// import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';
import withRouter from '../../components/common/withRouter';

const Login = () => {
	document.title = 'Iniciar sesión';

	const dispatch = useDispatch();
	const navigate = useNavigate();

	// * Clean redux state
	useEffect(() => {
		dispatch(resetState());
	}, []);

	const [passwordShow, setPasswordShow] = useState(false);

	const selectLogin = createSelector(
		state => state.Login,
		state => ({
			error: state.error,
			loading: state.loading,
		})
	);

	const { loading } = useSelector(selectLogin);

	const validation = useFormik({
		// enableReinitialize, use this flag when initial values needs to be changed
		enableReinitialize: true,

		initialValues: {
			username: '',
			password: '',
		},

		validationSchema: Yup.object({
			username: Yup.string().required('Ingresar usuario'),
			password: Yup.string().required('Ingresar contraseña'),
		}),

		onSubmit: user => {
			dispatch(loginUser({ user, navigate }));
		},
	});

	return (
		<>
			<div className="position-absolute d-flex h-100 w-100 justify-content-center align-items-center pb-5">
				<Container>
					<Row className="justify-content-center">
						<Col md={8} lg={6} xl={5} className="px-4 px-md-0">
							<Card className="mt-4">
								<CardBody className="p-4">
									<h3 className="pt-1 pb-0 px-2">Iniciar sesión</h3>
									<p className="pt-0 pb-1 px-2 text-muted" style={{ marginTop: -2 }}>
										Ingresa con las credenciales enviadas
									</p>

									<div className="p-2">
										<Form
											onSubmit={event => {
												event.preventDefault();
												validation.handleSubmit();
												return false;
											}}
											action="#">
											<div className="mb-3">
												<Input
													name="username"
													className="form-control"
													placeholder="Usuario"
													type="text"
													autocorrect="off"
													autocapitalize="none"
													style={{ height: 43 }}
													onChange={validation.handleChange}
													onBlur={validation.handleBlur}
													value={validation.values.username || ''}
													invalid={validation.touched.username && validation.errors.username}
												/>

												{validation.touched.username && validation.errors.username ? (
													<FormFeedback type="invalid">
														{validation.errors.username}
													</FormFeedback>
												) : null}
											</div>

											<div className="mb-3">
												<div className="position-relative auth-pass-inputgroup mb-3">
													<Input
														name="password"
														value={validation.values.password || ''}
														type={passwordShow ? 'text' : 'password'}
														className="form-control"
														placeholder="Contraseña"
														style={{ height: 43 }}
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
														invalid={
															validation.touched.password && validation.errors.password
														}
													/>

													{validation.touched.password && validation.errors.password ? (
														<FormFeedback type="invalid">
															{validation.errors.password}
														</FormFeedback>
													) : null}

													<button
														className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted d-flex h-100 align-items-center d-none"
														type="button"
														id="password-addon"
														onClick={() => setPasswordShow(!passwordShow)}>
														<i className="ri-eye-fill align-middle fs-20"></i>
													</button>
												</div>
											</div>

											<div className="mt-4 pt-2">
												<Button
													type="submit"
													color="success"
													className="btn btn-success w-100 fs-14"
													style={{ height: 45 }}
													disabled={loading}>
													{loading ? <Spinner size="sm" className="me-2" /> : null}
													Iniciar sesión
												</Button>
											</div>
										</Form>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>

			<ToastContainer closeButton={false} />
		</>
	);
};

const loginWithRouter = withRouter(Login);

export { loginWithRouter as Login };
