import React from 'react';
import { Col, Row } from 'reactstrap';

import classnames from 'classnames';

import { getHumanTimeDifference } from '../../helpers/dates_helper';

const OtherEventItem = ({ mapRef, event, setFocusedElement, focusedElement, hoveredElement, setHoveredElement }) => {
	const element_type = event.type === 'stationary_period' ? 'marker' : 'polyline';

	const getTitle = () => {
		if (event.type === 'stationary_period') {
			return 'Inactividad';
		}

		if (event.type === 'polyline_group') {
			if (event.is_walking) {
				return 'A pie';
			}

			return `${(event.total_distance / 1000).toFixed(2)} km`;
		}

		return null;
	};

	const getIconName = () => {
		if (event.type === 'stationary_period') {
			return 'ri-time-fill';
		}

		if (event.type === 'polyline_group') {
			if (event.is_walking) {
				return 'ri-walk-fill';
			}

			return 'ri-truck-fill';
		}

		return null;
	};

	const time_difference = getHumanTimeDifference(
		event.type === 'stationary_period' ? event.duration : event.total_duration
	);

	const onClick = () => {
		setFocusedElement({ type: element_type, id: event.id });

		// Center map on polyline
		if (event.type === 'polyline_group') {
			mapRef.current.map.panTo({
				lat: event.middle_coordinates.latitude,
				lng: event.middle_coordinates.longitude,
			});
		}
	};

	const onMouseEnter = () => {
		setHoveredElement({ type: element_type, id: event.id });
	};

	const onMouseLeave = () => {
		setHoveredElement({ type: null, id: null });
	};

	return (
		<Row
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			className={classnames(
				'd-flex align-items-center gap-2 mx-2 px-2 rounded cursor-pointer map-other-event-item',
				{ hovered: hoveredElement?.type === element_type && hoveredElement?.id === event?.id },
				{ selected: focusedElement?.type === element_type && focusedElement?.id === event?.id }
			)}>
			<div
				style={{
					height: 44,
					width: 8,
					marginLeft: 11,
					padding: 0,
					backgroundColor: '#299CDB',
				}}
			/>

			<Col className="col-auto mx-0 px-0">
				<div
					className="bg-dark-subtle shadow"
					style={{
						display: 'inline-flex',
						height: 30,
						minWidth: 30,
						borderRadius: 15,
					}}>
					<div className="d-flex h-100 w-100 justify-content-center align-items-center px-0 mx-0">
						<i className={`${getIconName()} text-body-secondary`} style={{ fontSize: 15 }}></i>
					</div>
				</div>
			</Col>

			<Col className="mx-0 px-0">
				<h5 className="fs-14 mx-0 px-0" style={{ marginBottom: -1 }}>
					{getTitle()}
				</h5>

				<span className="fs-10 my-0 mx-0">{time_difference}</span>
			</Col>
		</Row>
	);
};

export default OtherEventItem;
